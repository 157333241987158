import React from 'react';

import { StaticImage } from "gatsby-plugin-image"
import Layout from '../components/layout/Layout';

import { Link } from 'gatsby';
import NewsletterSub from '../components/NewsletterSub';


import InformeGri2018 from "../files/informe-gri-unilimpio-2018.pdf";

import InformeCOP2018 from "../files/informe-cop-unilimpio-2018.pdf";
import InformeCOP2019 from "../files/informe-cop-unilimpio-2019.pdf";
import InformeCOP2020 from "../files/informe-cop-unilimpio-2020.pdf";
import InformeCOP2021 from "../files/informe-cop-unilimpio-2021.pdf";


const siteVerOpts = [

  {
    label: 'Ecuador',
    value: '',


  },

  {
    label: 'Internacional',
    value: '/export/es/responsabilidad-social/',


  },
];

const langOpts = [


  {
    label: 'ES',
    value: '',

  },


  {
    label: 'EN',
    value: `/export/en/social-responsability/`,

  },


];

const seo={
  title: "Responsabilidad Social  ",
  desc:  "Somos una empresa responsable, transparente y solidaria. Nuestra empresa ha sido reconocida a nivel local por su gestión en responsabilidad social empresarial. Formamos parte del UN Global Compact y nos adherimos al Global Reporting Initiative.",
  image: "resppnsabilidad-social-banner.jpg",
  pathname: "/responsabilidad-social/",
  alternateEn: "/export/en/social-responsability/",
  alternateEs: "/export/es/responsabilidad-social/",
  alternateEsec: "/responsabilidad-social/",
  alternateDefault: "/export/es/responsabilidad-social/"
}


 const ResponsabilidadSocial = ({location}) => (





  <Layout location={location} crumbLabel="Responsabilidad Social Empresarial"
  siteVerOpts={siteVerOpts} langOpts={langOpts}
  alternates alternateEn={seo.alternateEn} alternateEs={seo.alternateEs} alternateEsec={seo.alternateEsec} alternateDefault={seo.alternateDefault}
          title={seo.title} desc={seo.desc} banner={seo.image} pathname={seo.pathname}>



    <div className="bg-fixed" >
       <StaticImage src="../images/responsabilidad-social.jpg"
                className="object-contain"
                alt="Unilimpio es una empresa con Resposabilidad Social"
                placeholder="blurred"
                layout="fullWidth"/>
    </div>

    
    
<section className="bg-white flex justify-center mb-0">
  <div className="max-w-7xl mx-10 py-16 px-4 sm:px-6 lg:py-24 lg:px-6">
    <div className="max-w-3xl mx-auto text-center">
      <h1 className="text-5xl font-semibold  text-lime-500">  SOSTENIBILIDAD</h1><br/>
      <h2 className=" italic font-mormal text-center text-primary text-2xl text-blue-900 ">"Construyendo hoy nuestro legado para las futuras generaciones. " </h2>

    </div>
  </div>
</section>



<section id="Nuestro compromiso con la sociedad y medio ambiente" className=" bg-white mt-0">     
  <div className="mx-auto max-w-7xl py-12 px-6 lg:px-8 lg:py-24">



    <div className="space-y-12">

      <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
        <h2 className="text-3xl text-center font-semibold sm:text-4xl text-primary-lighter">Nuestro compromiso con la sociedad y el medio ambiente</h2>
      </div>
      <ul class="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-2 lg:gap-x-8">
        <li>
          <div className="space-y-4 ">
            <div className="aspect-w-3 aspect-h-2 ">
              <StaticImage src="../images/derechos-humanos.jpg"
                         className="rounded-md"
                         alt="Unilimpio es una empresa con responsabilidad social empresarial"
                         placeholder="blurred"
                         layout="fullWidth"/>
            </div>

           


           <div className="space-y-2">
              <div className="space-y-1 text-lg font-medium leading-6">
              <h2 className="text-2xl text-center font-semibold leading-tight text-lime-500  ml-30">
          Derechos Humanos
          </h2>
          <p className="mt-8 text-base font-normal leading-relaxed text-gray-600 ">
          Nos comprometemos a respetar y promover los derechos fundamentales de todos nuestros empleados y colaboradores. Nuestra empresa se rige por principios de igualdad, no discriminación y diversidad, garantizando un entorno laboral seguro y libre de abusos. Valoramos y respetamos la libertad de expresión, la privacidad y la dignidad de cada individuo. Estamos comprometidos en cumplir con los estándares internacionales de derechos humanos en todas nuestras operaciones.
          </p>
          <div className="flex flex-wrap mx-auto text-left pb-6">
          <Link to="../politica-derechos-humanos" className="mt-2 text-primary-default inline-flex items-center text-lg text-semibold">Conozca nuestras políticas de derechos humanos
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link>
        </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="space-y-4">
            <div className="aspect-w-3 aspect-h-2">
              <StaticImage src="../images/estandares-laborales.jpg"
                         className="rounded-md"
                         alt="compromiso de lucha contra la corrupción"
                         placeholder="blurred"
                         layout="fullWidth"/>
            </div>
            <div className="space-y-2">
              <div className="space-y-1 text-lg font-medium leading-6">
              <h2 className="text-2xl text-center font-semibold leading-tight text-lime-500  ml-30">
          Estándares Laborales
          </h2>
          <p className="mt-8 text-base font-normal leading-relaxed text-gray-600 ">
          Nos comprometemos a garantizar condiciones de trabajo seguras y saludables para todos nuestros empleados. Respetamos y cumplimos con las leyes laborales y promovemos la igualdad de oportunidades y la no discriminación en el empleo. Fomentamos el desarrollo profesional, ofreciendo capacitación y oportunidades de crecimiento. Valoramos y reconocemos el trabajo de nuestros empleados, promoviendo un ambiente laboral justo y equitativo.
          </p>
          <div className="flex flex-wrap mx-auto text-left pb-6">
          <Link to="../politica-estandares-laborales" className="mt-2 text-primary-default inline-flex items-center text-lg text-semibold">Conozca nuestras políticas de estándares laborales
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link>
        </div>


              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="space-y-4">
            <div className="aspect-w-3 aspect-h-2">
              <StaticImage src="../images/siembra-de-arboles.jpg"
                         className="rounded-md"
                         alt="compromiso de lucha contra la corrupción"
                         placeholder="blurred"
                         layout="fullWidth"/>
            </div>
            <div className="space-y-2">
              <div className="space-y-1 text-lg font-medium leading-6">
              <h2 className="text-2xl text-center font-semibold leading-tight text-lime-500 ml-30"> Medio Ambiente</h2>
          <p className="mt-8 text-base font-normal leading-relaxed text-gray-600">
          En Unilimpio creemos firmemente que ser sustentables es el único modelo de gestión de empresas que es válido hoy por hoy. Ninguna ganancia económica el día de hoy puede justificar el comprometer los recursos y medios de subsistencia de las futuras generaciones.
          </p>
          <div className="flex flex-wrap mx-auto text-left pb-6">
          <Link to="../politica-medio-ambiente" className="mt-2 text-primary-default inline-flex items-center text-lg text-semibold">Conozca nuestras políticas del medio ambiente
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link>
        </div>
              </div>
            </div>
          </div>
        </li>

        <li>
          <div className="space-y-4">
            <div className="aspect-w-3 aspect-h-2">
              <StaticImage src="../images/corrupcion.jpg"
                         className="rounded-md"
                         alt="compromiso de lucha contra la corrupción"
                         placeholder="blurred"
                         layout="fullWidth"/>
            </div>

            
            

           <div className="space-y-2">
              <div className="space-y-1 text-lg font-medium leading-6">
              <h2 className="text-2xl text-center font-semibold leading-tight text-lime-500 ml-30">Compromiso de lucha contra la corrupción</h2>
          <p className="mt-8 text-base font-normal leading-relaxed text-gray-600">
          Unilimpio ha declarado y formalizado su compromiso de luchar contra la corrupción en cada uno de los encadenamientos productivos a los que pertenecemos. Contribuimos con la sociedad y el desarrollo económico haciendo negocios de forma ética y transparente, sin ceder ante solicitud de coimas para ganar licitaciones o contratos. Así mismo pertenecemos a una alianza de empresas, tanto de Ecuador como de la región, entre las cuales compartimos el mismo llamado a la acción y el mismo código moral.
          </p>
          <div className="flex flex-wrap mx-auto text-left pb-6">
          <Link to="../lucha-contra-la-corrupcion" className="mt-2 text-primary-default inline-flex items-center text-lg text-semibold">Conozca nuestra lucha contra la corrupción
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link>
        </div>
              </div>
            </div>
          </div>
        </li>

        <li>
          <div className="space-y-4">
            <div className="aspect-w-3 aspect-h-2">
              <StaticImage src="../images/transparencia.jpg"
                         className="rounded-md"
                         alt="compromiso de lucha contra la corrupción"
                         placeholder="blurred"
                         layout="fullWidth"/>
            </div>

            <div className="space-y-2">
              <div className="space-y-1 text-lg font-medium leading-6">
                <h2 className="text-2xl text-center font-semibold leading-tight text-lime-500  ml-30">Transparencia</h2>
                 <p className="mt-8 text-base font-normal text-gray-600 leading-relaxed  ">
          En una época en la que cualquiera puede llamarse amigable con el medio ambiente, en Unilimpio creemos en la necesidad de comunicarnos con nuestras partes interesadas de una forma honesta, clara y transparente sobre los esfuerzos que realizamos día a día para lograr que nuestra operación sea cada vez más sustentable. Para esto, a partir del año 2018 nos hemos adherido a GRI (Global Reporting Iniciative), modelo Internacional de transparencia en la comunicación de información relacionada a desarrollo económico y sustentabilidad. </p>
          
        <div className="flex flex-wrap mx-auto text-left">
          <Link href={ InformeGri2018 } target="_blank" rel="noreferrer" className="mt-2 text-primary-default inline-flex items-center text-lg text-semibold"> Informe GRI 2018 
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link> 
        </div>
        
              </div>
            </div>
          </div>
        </li>
       
        <li>
          <div className="space-y-4">
            <div className="aspect-w-3 aspect-h-2">
              <StaticImage src="../images/pacto-global.jpg"
                         className="rounded-md"
                         alt="Unilimpio forma parte del Pacto Global"
                         placeholder="blurred"
                         layout="fullWidth"/>
            </div>

           <div className="space-y-2">
              <div className="space-y-1 text-lg font-medium leading-6">
              <h2 className="text-2xl text-center font-semibold leading-tight text-lime-500 ml-30">Adheridos al Pacto Global</h2>
          <p className="mt-8 text-base font-normal text-gray-600 leading-relaxed ">
          Desde 2018, Unilimpio forma parte del Pacto Global iniciativa global que cuenta con el auspicio de las Naciones Unidas y que busca que las organizaciones privadas a nivel mundial se involucren activamente en la consecución de los Objetivos de Desarrollo Sustentable (ODS) para el 2030.
          </p>
          <div className="flex flex-wrap mx-auto text-left">
          <Link href={ InformeCOP2018 } target="_blank" rel="noreferrer" className="mt-2 text-primary-default inline-flex items-center text-lg text-semibold"> Informe COP 2018 
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link> 
        </div>
        <div className="flex flex-wrap mx-auto text-left">
          <Link href={ InformeCOP2019 } target="_blank" rel="noreferrer" className="mt-2 text-primary-default inline-flex items-center text-lg text-semibold"> Informe COP 2019 
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link> 
        </div>
        <div className="flex flex-wrap mx-auto text-left">
          <Link href={ InformeCOP2020 } target="_blank" rel="noreferrer" className="mt-2 text-primary-default inline-flex items-center text-lg text-semibold"> Informe COP 2020 
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link> 
        </div>
        <div className="flex flex-wrap mx-auto text-left">
          <Link href={ InformeCOP2021 } target="_blank" rel="noreferrer" className="mt-2 text-primary-default inline-flex items-center text-lg text-semibold"> Informe COP 2021 
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link> 
        </div>
      
              </div>
            </div>
          </div>
        </li>
       
      </ul>
    </div>
  </div>
</section>


<section id="Nuestras actividades en responsbailidad social" className="relative bg-gray-50 px-6 pt-16 pb-20 lg:px-8 lg:pt-24 lg:pb-28">
  <div className="absolute inset-0">
    <div className="h-1/3 bg-white sm:h-2/3"></div>
  </div>
  <div className="relative mx-auto max-w-7xl">
    <div className="text-center">
      <h2 className="text-3xl text-center font-semibold sm:text-4xl text-primary-lighter">Nuestras actividades en RSE</h2>
    </div>
    <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
      <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
        <div className="flex-shrink-0">
              <StaticImage src="../images/productos-de-limpieza-biodegradables.jpg"
                         className="rounded-md"
                         alt="Somos una empresa que fabrica productos de limpieza biodegradables"
                         placeholder="blurred"
                         layout="fullWidth"/>
        </div>
        <div className="flex flex-1 flex-col justify-between bg-white p-6">
          <div className="flex-1">
              <p className="text-xl font-semibold text-lime-500">Nuestros Productos de Limpieza Biodegrabales</p>
              <p className="mt-3 text-base text-gray-600">Todos nuestros productos de limpieza y absorbentes son altamente biodegradables. Son desarrollados bajo procesos ecológicos y socialmente responsables, siempre pensando en el medio ambiente. </p>
              <div className="flex flex-wrap mx-auto justify-left">
                 <Link to="/blog/utiliza-productos-biodegradables" className="mt-4 text-primary-lighter inline-flex items-center text-base text-bold">Más información
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
                 </Link>
              </div>
          </div>
        
        </div>
      </div>

      <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
        <div className="flex-shrink-0">
        <StaticImage src="../images/dia-voluntariado-playas-villamil.jpg"
                         className="rounded-md"
                         alt="Unilimpio formo parte de la limpieza de Playas Villamil"
                         placeholder="blurred"
                         layout="fullWidth"/>
        </div>
        <div className="flex flex-1 flex-col justify-between bg-white p-6">
          <div className="flex-1">
              <p className="text-xl font-semibold text-lime-500">Limpieza de Playas Villamil</p>
              <p className="mt-3 text-base text-gray-600">El objetivo de la limpieza de Playas Villamil fue concientizar a los adultos, niños y adolescentes sobre el enorme problema que ocasiona el uso de plástico de un solo uso para los océanos y mares.</p>
              <div className="flex flex-wrap mx-auto justify-left">
                 <Link to="/blog/cuidemos-a-nuestras-playas-y-oceanos" className="mt-4 text-primary-lighter inline-flex items-center text-base text-bold">Más información
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
                 </Link>
              </div>
          </div>
          
        </div>
      </div>

      <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
        <div className="flex-shrink-0">
        <StaticImage src="../images/campana-social-lavado-de-manos-usfq.jpg"
                         className="rounded-md"
                         alt="Unilimpio estuvo presente con su campaña lavado de manos en la USFQ"
                         placeholder="blurred"
                         layout="fullWidth"/>
        </div>
        <div className="flex flex-1 flex-col justify-between bg-white p-6">
          <div className="flex-1">
              <p className="text-xl font-semibold text-lime-500">Campaña Social del Lavado de Manos</p>
              <p className="mt-3 text-base text-gray-600">Con nuestras campañas de Lavado de Manos buscamos constantemente enseñar a la sociedad, de manera divertida, el adecuado lavado de manos y sus consecuencia si no lo hacemos.</p>
              <div className="flex flex-wrap mx-auto justify-left">
                 <Link to="/blog/adecuado-lavado-de-manos" className="mt-4 text-primary-lighter inline-flex items-center text-base text-semibold">Más información
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
                 </Link>
              </div>
          </div>
       
        </div>
      </div>

      <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
        <div className="flex-shrink-0">
        <StaticImage src="../images/campana-por-el-dia-mundial-del-lavado-de-manos.jpg"
                         className="rounded-md"
                         alt="Unilimpio apoya al día Mundial del Lavado de manos"
                         placeholder="blurred"
                         layout="fullWidth"/>
        </div>
        <div className="flex flex-1 flex-col justify-between bg-white p-6">
          <div className="flex-1">
              <p className="text-xl font-semibold text-lime-500">Día Mundial del Lavado de Manos</p>
              <p className="mt-3 text-base text-gray-600">El pasado viernes 15 de octubre nos unimos a la celebración del Día Mundial del Lavado de Manos, fecha otorgada por la Organización Mundial de la Salud. Estuvimos presentes en varios hospitales con nuestra campaña La Importancia del adecuado Lavado de Manos.</p>
              <div className="flex flex-wrap mx-auto justify-left">
                 <Link to="/blog/dia-mundial-del-lavado-de-manos" className="mt-4 text-primary-lighter inline-flex items-center text-base text-semibold">Más información
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
                 </Link>
              </div>
          </div>
       
        </div>
      </div>

      <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
        <div className="flex-shrink-0">
        <StaticImage src="../images/unilimpio-en-voluntariado-fundacion-cecilia-rivadeneira.jpg"
                         className="rounded-md"
                         alt="Unilimpio estuvo presente en el voluntariado en la fundación Cecilia Rivadeneira"
                         placeholder="blurred"
                         layout="fullWidth"/>
        </div>
        <div className="flex flex-1 flex-col justify-between bg-white p-6">
          <div className="flex-1">
              <p className="text-xl font-semibold text-lime-500">Voluntariado en Fundación Cecilia Rivadeneira</p>
              <p className="mt-3 text-base text-gray-600">Estuvimos en la Fundación Cecilia Rivadeneira realizando varias actividades de voluntariado para contribuir con la fundación. Varios de nuestros colaboradores llevaron a sus espos@s e hijos con el objetivo de fomentarles el valor del VOLUNTARIADO desde pequeños. </p>
              <div className="flex flex-wrap mx-auto justify-left">
                 <Link to="/blog/voluntariado-en-la-fundacion-cecilia-rivadeneira" className="mt-4 text-primary-lighter inline-flex items-center text-base text-semibold">Más información
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
                 </Link>
              </div>
          </div>
       
        </div>
      </div>

      <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
        <div className="flex-shrink-0">
        <StaticImage src="../images/productos-libres-de-crueldad-animal.jpg"
                         className="rounded-md"
                         alt="Unilimpio es una empresa socialmente responsable y todos sus productos son libres de crueldad animal"
                         placeholder="blurred"
                         layout="fullWidth"/>
        </div>
        <div className="flex flex-1 flex-col justify-between bg-white p-6">
          <div className="flex-1">
              <p className="text-xl font-semibold text-lime-500">Todos nuestros productos cosméticos son libres de crueldad animal</p>
              <p className="mt-3 text-base text-gray-600">Nuestra línea de productos cosméticos Dr.Clean no realiza ensayos en animales de ninguno de sus productos. Todos los ingredientes que utilizamos en los productos cosméticos se encuentran registrados en la base de datos de ingredientes químicos de la UE conocida como CosIng.</p>
              <div className="flex flex-wrap mx-auto justify-left">
                 <Link to="/blog/utiliza-productos-libres-de-crueldad-animal" className="mt-4 text-primary-lighter inline-flex items-center text-base text-semibold">Más información
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
                 </Link>
              </div>
          </div>
       
        </div>
      </div>

      <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
        <div className="flex-shrink-0">
        <StaticImage src="../images/absorbentes-fsc.jpg"
                         className="rounded-md"
                         alt="Somos una empresa certificada FSC para nuestros absorbentes"
                         placeholder="blurred"
                         layout="fullWidth"/>
        </div>
        <div className="flex flex-1 flex-col justify-between bg-white p-6">
          <div className="flex-1">
              <p className="text-xl font-semibold text-lime-500">Somos Certificados FSC® (Forest Stewardship Council®)</p>
              <p className="mt-3 text-base text-gray-600">Nos enorgullece anunciar que somos la primera empresa ecuatoriana en certificarnos en FSC® para nuestra Toalla de Manos en Z Biosolutions y Servilleta Dispensada Unilimpio.
              </p>
              <div className="flex flex-wrap mx-auto justify-left">
                 <Link to="/blog/somos-certificados-fsc" className="mt-4 text-primary-lighter inline-flex items-center text-base text-semibold">Más información
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
                 </Link>
              </div>
          </div>
       
        </div>
      </div>

      <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
        <div className="flex-shrink-0">
        <StaticImage src="../images/logo-ceres-informacion.jpg"
                         className="rounded-md"
                         alt="UUnilimpio es miembro de CERES Ecuador"
                         placeholder="blurred"
                         layout="fullWidth"/>
        </div>
        <div className="flex flex-1 flex-col justify-between bg-white p-6">
          <div className="flex-1">
              <p className="text-xl font-semibold text-lime-500">Somos miembro de CERES Ecuador</p>
              <p className="mt-3 text-base text-gray-600">CERES es la Red más importante de organizaciones comprometidas y que promueven la Responsabilidad Social en el Ecuador.</p>
              <div className="flex flex-wrap mx-auto justify-left">
                 
            <a href="https://www.redceres.com/" target="_blank"  rel="noreferrer" className="mt-4 text-primary-lighter inline-flex items-center text-base text-semibold">Más información</a>
         
                
              </div>
          </div>
       
        </div>
      </div>

      <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
        <div className="flex-shrink-0">
        <StaticImage src="../images/redni.jpg"
                         className="rounded-md"
                         alt="Somos parte de la campana social REDNI, campana social para reducir la destrución infantil"
                         placeholder="blurred"
                         layout="fullWidth"/>
        </div>
        <div className="flex flex-1 flex-col justify-between bg-white p-6">
          <div className="flex-1">
              <p className="text-xl font-semibold text-lime-500">Nos unimos a la Campaña Social #DaleAlegría para Combatir la Desnutrición Infantil </p>
              <p className="mt-3 text-base text-gray-600">#DaleAlegría, una campaña social liderada por Fundación REDNI para combatir la desnutrición crónica infantil en Ecuador. Uno de cada cinco niños en el país sufre de esta condición, un dato alarmante que nos motiva a actuar. Estamos emocionados de ser parte de esta iniciativa y contribuir a mejorar la situación de estas familias.</p>
              <div className="flex flex-wrap mx-auto justify-left">
                 <Link to="/blog/campana-social-dale-alegria" className="mt-4 text-primary-lighter inline-flex items-center text-base text-semibold">Más información
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
                 </Link>
              </div>
          </div>
       
        </div>
      </div>

    </div>
  </div>
</section>


<section id="Somos fabricantes" className="mx-auto w-full">
  <div className="bg-lime-500 flex flex-col md:flex-row">
   <div className="flex-1">
    <StaticImage src="../images/paneles-solares.jpg"
                      className="object-fit"
                      alt="Instalación de paneles solares en Unilimpio"
                      placeholder="blurred"
                      layout="constrained"/>
    </div>
    <div className="flex-1 px-4 py-4 sm:px-6 lg:px-8 lg:py-2">
    
      <h2 className="mt-6  text-4xl  font-semibold text-white text-left">
        Utilizamos 70% de energía renovable
      </h2>
      <p className="mt-6 text-2xl text-left font-normal text-white ">
      Hemos instalado paneles solares en nuestra planta de producción. Con esta iniciativa reducimos 70% de nuestro consumo de energía.Este paso no solo nos ayuda a cuidar el planeta, sino que también refleja nuestro continuo esfuerzo por innovar y mejorar en todos los aspectos de nuestra operación. <br/> <br/> ¡Juntos, construimos un futuro más verde!
      </p>
      <div className="flex flex-wrap mx-auto justify-left">
                 <Link to="/blog/utilizamos-energia-renovable" className="mt-4 text-white inline-flex items-center text-lg text-semibold">Más información
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
                 </Link>
              </div>
    </div>
  </div>
</section>


<section id="Campaña Interna Yo Construyo un Futuro Sostenible" className="">
 <div className="bg-sky-600 pb-0">
  <div className="mx-auto max-w-7xl py-12 px-6 lg:px-8 lg:py-24">
    <div className="space-y-12">
      <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
        <h2 className="text-4xl font-bold tracking-tight text-white sm:text-5xl text-center">... CONSTRUYENDO UN FUTURO SOSTENIBLE</h2>
        <p className="text-3xl text-gray-300 text-center">¿Qué acciones realizamos actualmente dentro de Unilimpio para contribuir con el problema ambiental del uso de plástico de un solo uso? </p>
      </div>
      <ul role="" className="space-y-4 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:grid-cols-3 lg:gap-8">
        <li className="rounded-lg bg-sky-500 py-10 px-6 text-center xl:px-10 xl:text-left">
          <div className="space-y-6 xl:space-y-10">
          <div className="flex-shrink-0">
             <StaticImage src="../images/envases-con-materiales-reciclables.jpg"
                         className="rounded-md"
                         alt="Unilimpio fabrica productos con envases reciclables"
                         placeholder="blurred"
                         layout="fullWidth"/>
           </div>
            <div className="space-y-2 xl:flex xl:items-center xl:justify-between">
              <div className="space-y-1 text-xl font-normal leading-6">
              <h3 className= "text-lime-400 text-center text-2xl text-bold pb-4">Empaques Reciclables</h3>
                <p className="text-lg text-white">La mayoría de los empaques utilizados en nuestros productos fabricados están hechos de materiales que pueden ser reciclados.</p>
              </div>
            </div>
          </div>
        </li>

        <li className="rounded-lg bg-sky-500 py-10 px-6 text-center xl:px-10 xl:text-left">
          <div className="space-y-6 xl:space-y-10">
          <div className="flex-shrink-0">
             <StaticImage src="../images/envases-con-graficos-informativos-para-ser-reciclados.jpg"
                         className="rounded-md"
                         alt="Nuestros envases cuentan con gráficos informativos para poder reciclar"
                         placeholder="blurred"
                         layout="fullWidth"/>
           </div>
            <div className="space-y-2 xl:flex xl:items-center xl:justify-between">
            <div className="">
            <h3 className= "text-lime-400 text-center text-2xl text-bold pb-4">Gráficos Informativos</h3>
                <p className="text-lg text-white">Hemos incluido gráficos informativos en las etiquetas de los productos, para comunicar el tipo de material de los que están hechos los empaques, ayudando al consumidor para la disposición final. </p>
              </div>
            </div>
          </div>
        </li>

        <li className="rounded-lg bg-sky-500 py-10 px-6 text-center xl:px-10 xl:text-left">
          <div className="space-y-6 xl:space-y-10">
          <div className="flex-shrink-0">
             <StaticImage src="../images/envases-doypack.jpg"
                         className="rounded-md"
                         alt="Unilimpio fabrica productos con envases con menos porcentaje de plástico"
                         placeholder="blurred"
                         layout="fullWidth"/>
           </div>
            <div className="space-y-2 xl:flex xl:items-center xl:justify-between">
            <div className="space-y-1 text-xl font-normal leading-6">
            <h3 className= "text-lime-400 text-center text-2xl text-bold pb-4">Envases con menos Plástico</h3>
                <p className="text-lg text-white">Implementación de envases doypack con 70% menos plástico y envases galón con 12% menos plástico.</p>
              </div>
            </div>
          </div>
        </li>

      </ul>
    </div>
  </div>
</div>
</section>



<section id="Lanzamiento Campaña Interna Yo Construyo un Futuro Sostenible" className="">
 <div className="bg-sky-500">
  <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
    <div className="space-y-12 lg:grid lg:grid-cols-2 lg:gap-8 lg:space-y-0">
      <div className="lg:col-span-2">
      <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
        <h2 className="text-4xl font-bold tracking-tight text-white  text-center bg-sky-600">Yo Construyo un Futuro Sostenible</h2>
        <p className="text-2xl text-gray-300 ">Lanzamos nuestra campaña interna #YoConstruyoUnFuturoSostenible con el objetivo de concientizar a todos nuestros colaboradores acerca del grave problema ambiental que tiene como consecuencia el uso del plástico de un solo uso. Hemos implementado nuevas acciones dentro de nuestra empresa: </p>
      </div>
        <ul className="space-y-12  sm:space-y-0 mt-14 lg:gap-x-8 lg:space-y-0">
          <li className="sm:py-8">
            <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
              <div className="aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4">
              <StaticImage src="../images/campana-interna-yo-construyo-un-futuro-sostenible.jpg"
                    className="flex justify-center mx-6"
                    alt="Lanzamos nuestra campaña interna Yo Construyo un Futuro Sostenible"
                    placeholder="blurred"
                    layout="constrained"/>
              </div>
              <div className="sm:col-span-2">
                <div className="space-y-4">
                  <div className="text-2xl leading-6 font-semibold space-y-1">
                    <h3 className= "text-lime-400" >Kit YO SOY SOSTENIBLE</h3>
                  </div>
                  <div className="text-lg">
                    <p className="text-white">Eliminamos todo tipo de desechables de un solo uso y remplazamos por envases reutilizables. De igual forma, realizamos la entrega a cada colaborador de 1 kit “Yo soy Sostenible”, el cual contiene termos y fundas reutilizables.</p>
                  </div>
                
                </div>
              </div>
            </div>
          </li>
          <li className="sm:py-8">
            <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
              <div className="aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4">
              <StaticImage src="../images/capacitaciones.jpg"
                    className="flex justify-center mx-6"
                    alt="Realizamos charlas de concientización a nuestros colaboradores acerca del problema del uso del plástico de un solo uso"
                    placeholder="blurred"
                    layout="constrained"/>
              </div>
              <div className="sm:col-span-2">
                <div className="space-y-4">
                  <div className="text-2xl leading-6 font-semibold space-y-1">
                    <h3 className= "text-lime-400">Charlas de Concientización</h3>
                  </div>
                  <div className="text-lg">
                    <p className="text-white">Realizar constantemente charlas de concientización acerca de este problema para trasnformar a nuestros colaboradores en voceros de la campaña para que transmitan y vivan nuestra filosofía de ser sostenibles dentro y fuera de la empresa.</p>
                  </div>
                  <ul className="flex space-x-5">
                  </ul>
                </div>
              </div>
            </div>
          </li>
          <li className="sm:py-8">
            <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
              <div className="aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4">
              <StaticImage src="../images/envases-con-menos-porcentaje-de-plastico.jpg"
                    className="flex justify-center mx-6"
                    alt="Realizamos productos con menos porcentaje de plástico y envases doypack"
                    placeholder="blurred"
                    layout="constrained"/>
              </div>
              <div className="sm:col-span-2">
                <div className="space-y-4">
                  <div className="text-2xl leading-6 font-semibold space-y-1">
                    <h3 className= "text-lime-400">Envases con % de plástico y canecas</h3>
                  </div>
                  <div className="text-lg">
                    <p className="text-white">Continuar desarrollando productos con menos % de plástico como nuestros productos doypack. Y, de igual forma, seguir impulsando el consumo de canecas de relleno reutilizables en nuestros clientes.</p>
                  </div>
                  <ul className="flex space-x-5">
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none pb-24">
        <p className="text-3xl font-semibold  text-white  text-center ">Yo CRONSTRUYO un futuro sostenible, y tú, ¿te unes al cambio?</p>
      </div>
 </div>
</section>


















<NewsletterSub/>
  </Layout>
);
export default ResponsabilidadSocial;
